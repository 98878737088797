import * as yup from 'yup';
import i18n from '@/i18n';

const { t } = i18n.global;

export const LoginValidationSchema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

export const ForgotPasswordValidationSchema = yup
    .string()
    .required(`${t('required_field', 1, { field: t('email', 1) })}`)
    .email(`${t('invalid_email', 1)}`);

export const ResetPasswordValidationSchema = yup.object().shape({
    password: yup.string().required(`${t('required_field', 1, { field: t('password', 1) })}`),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], `${t('password_does_not_match', 2)}`),
});

const AddressValidationSchema = yup.object().shape({
    street: yup.string().required(`${t('required_field', 1, { field: t('street', 1) })}`),
    number: yup.string().required(`${t('required_field', 1, { field: t('number', 1) })}`),
    addition: yup.string(),
    postal_code_id: yup.string().required(`${t('required_field', 1, { field: t('city', 1) })}`),
    country: yup.string().required(`${t('required_field', 1, { field: t('country', 1) })}`),
});

const ContactValidationSchema = yup.object().shape({
    gender: yup
        .string()
        .required(`${t('required_field', 1, { field: t('gender', 1) })}`)
        .oneOf(['male', 'female', 'other'], `${t('invalid_gender', 1)}`),
    first_name: yup.string().required(`${t('required_field', 1, { field: t('first_name', 1) })}`),
    last_name: yup.string().required(`${t('required_field', 1, { field: t('last_name', 1) })}`),
    phone: yup.number().required(`${t('required_field', 1, { field: t('phone', 1) })}`),
    email: yup
        .string()
        .required(`${t('required_field', 1, { field: t('email', 1) })}`)
        .email(`${t('invalid_email', 1)}`),
    date_of_birth: yup
        .date(`${t('invalid_date_of_birth', 1)}`)
        .required(`${t('required_field', 1, { field: t('date_of_birth', 1) })}`)
        .typeError(`${t('invalid_date_of_birth', 1)}`),
    function: yup.string().required(`${t('required_field', 1, { field: t('function', 1) })}`),
    language: yup
        .string()
        .required(`${t('required_field', 1, { field: t('language', 1) })}`)
        .oneOf(['nl', 'fr', 'en'], `${t('invalid_language', 1)}`),
});

export const CreateCompanyValidationSchema = yup.object().shape({
    enterprise_number: yup
        .string()
        .required(`${t('required_field', 1, { field: t('enterprise_number', 1) })}`)
        .matches(/^(?:BE)?\s*[0-1]?((\d[. ]*){9})$/, `${t('invalid_enterprise_number', 1)}`),
    business_name: yup.string().required(`${t('required_field', 1, { field: t('business_name', 1) })}`),
    legal_entity_type: yup.string().required(`${t('required_field', 1, { field: t('legal_entity_type', 1) })}`),
    phone: yup.string().required(`${t('required_field', 1, { field: t('phone', 1) })}`),
    email: yup
        .string()
        .required(`${t('required_field', 1, { field: t('email', 1) })}`)
        .email(`${t('invalid_email', 1)}`),
    physical_address: AddressValidationSchema,
    same_billing_address: yup.boolean(),
    billing_address: yup.object().when('same_billing_address', {
        is: false,
        then: AddressValidationSchema,
    }),
    contacts: yup.array().of(ContactValidationSchema),
});

export const validateEmail = (email) => {
    const reMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reMail.test(email)) {
        return false;
    }

    return true;
};

// export const validatePhone = (phone) => {
//     if ((phone.length > 0 && phone.length < 9) || phone.length > 21) {
//         return false;
//     }

//     return true;
// };

// export const validatePassword = (password, minLength) => {
//     if (password.length < minLength) {
//         return false;
//     }

//     return true;
// };

// export const validateNumberBetweenValues = (number, min, max) => {
//     if (number < min || number > max) {
//         return false;
//     }

//     return true;
// };

export const validateEan = (eanNumber) => {
    let total = 0;

    if (eanNumber.length !== 18) {
        return false;
    }

    if (!eanNumber.startsWith('54')) {
        return false;
    }

    for (let i = 0; i < eanNumber.length - 1; i += 1) {
        if (i % 2) {
            total += parseInt(eanNumber.charAt(i));
        } else {
            total += parseInt(eanNumber.charAt(i)) * 3;
        }
    }

    const nextTenFold = Math.ceil(total / 10) * 10;
    const lastNumber = parseInt(eanNumber.slice(-1));

    if (nextTenFold - total !== lastNumber) {
        return false;
    }

    return true;
};

// export const validateEnterpriseNumber = (n) => {
//     let isValid = true,
//         enterpriseNumber = null,
//         displayEnterpriseNumber = null;

//     if (n.match(/^(?:BE)?\s*[0-1]?((\d[. ]*){9})$/)) {
//         enterpriseNumber = n.replace(/^(?:BE)?\s*[0-1]?((\d[. ]*){9})$/g, function () {
//             return arguments[1].replace(/\D/g, '');
//         });

//         if (97 - (parseInt(enterpriseNumber.slice(0, 7), 10) % 97) !== parseInt(enterpriseNumber.slice(7, 9), 10)) isValid = false;
//         displayEnterpriseNumber = `BE 0${enterpriseNumber.substring(0, 3)}.${enterpriseNumber.substring(3, 6)}.${enterpriseNumber.substring(6, 9)}`;
//     } else {
//         isValid = false;
//     }

//     return { isValid, enterpriseNumber, displayEnterpriseNumber };
// };
